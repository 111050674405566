@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    height: 100%;
  }
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #e8f0fe inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #4b5675 !important;
}

/* For Firefox */
body {
  pointer-events: all !important;
  scrollbar-width: none;
}

.vjs-theme-forest {
  --vjs-theme-forest--primary: var(--bs-primary) !important;
}

.vjs-theme-forest .vjs-big-play-button,
.vjs-play-control {
  display: none !important;
}

.gl-video-player__wrapper,
.video-js {
  background-color: transparent;
  height: 100%;
  width: 100%;
}

.vjs-volume-panel,
.vjs-skip-forward-5,
.vjs-playback-rate,
.vjs-picture-in-picture-control,
.vjs-fullscreen-control {
  display: none !important;
}

.swiper-wrapper {
  pointer-events: none;
  user-select: none;
}
